import { hasNoValue } from './components/inputs/inputHelpers';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};
// Anything above this should be a special request
export const MAX_EXPORT_DATA_COUNT = 10000;
export const NOT_AVAILABLE = 'NOT AVAILABLE';

export const systemRoles = {
  // ioTec id user roles
  ADMIN: 'ADMIN',
  SUPER: 'SUPER',
  BASIC: 'BASIC',
};

export const rootAdminUserRoles = [
  systemRoles.ADMIN,
  systemRoles.SUPER
];

export const appPermissions = {
  CONSENT_VIEW: 'CONSENT_VIEW',
  BULK_CONSENT_VIEW: 'BULK_CONSENT_VIEW',

  CONSENT_TRIGGER: 'CONSENT_TRIGGER',
  BULK_CONSENT_TRIGGER: 'BULK_CONSENT_TRIGGER',
  CUSTOMER_CONSENT_TRIGGER: 'CUSTOMER_CONSENT_TRIGGER',

  CONSENT_TEMPLATE_VIEW: 'CONSENT_TEMPLATE_VIEW',
  CONSENT_TEMPLATE_EDIT: 'CONSENT_TEMPLATE_EDIT',

  CONSENT_DATA_CUSTODIAN_VIEW: 'CONSENT_DATA_CUSTODIAN_VIEW',
  CONSENT_DATA_CUSTODIAN_EDIT: 'CONSENT_DATA_CUSTODIAN_EDIT',

  ID_VERIFICATION_VIEW: 'ID_VERIFICATION_VIEW',
  BULK_ID_VERIFICATION_VIEW: 'BULK_ID_VERIFICATION_VIEW',

  ID_VERIFICATION_TRIGGER: 'ID_VERIFICATION_TRIGGER',
  BULK_ID_VERIFICATION_TRIGGER: 'BULK_ID_VERIFICATION_TRIGGER',
  CUSTOMER_ID_VERIFICATION_TRIGGER: 'CUSTOMER_ID_VERIFICATION_TRIGGER',

  PHONE_NUMBER_VERIFICATION_VIEW: 'PHONE_NUMBER_VERIFICATION_VIEW',
  BULK_PHONE_NUMBER_VERIFICATION_VIEW: 'BULK_PHONE_NUMBER_VERIFICATION_VIEW',

  PHONE_NUMBER_VERIFICATION_TRIGGER: 'PHONE_NUMBER_VERIFICATION_TRIGGER',
  BULK_PHONE_NUMBER_VERIFICATION_TRIGGER: 'BULK_PHONE_NUMBER_VERIFICATION_TRIGGER',
  CUSTOMER_PHONE_NUMBER_VERIFICATION_TRIGGER: 'CUSTOMER_PHONE_NUMBER_VERIFICATION_TRIGGER',

  TIN_VERIFICATION_VIEW: 'TIN_VERIFICATION_VIEW',
  BULK_TIN_VERIFICATION_VIEW: 'BULK_TIN_VERIFICATION_VIEW',

  TIN_VERIFICATION_TRIGGER: 'TIN_VERIFICATION_TRIGGER',
  BULK_TIN_VERIFICATION_TRIGGER: 'BULK_TIN_VERIFICATION_TRIGGER',
  CUSTOMER_TIN_VERIFICATION_TRIGGER: 'CUSTOMER_TIN_VERIFICATION_TRIGGER',

  AML_SCREENING_VIEW: 'AML_SCREENING_VIEW',
  BULK_AML_SCREENING_VIEW: 'BULK_AML_SCREENING_VIEW',

  AML_SCREENING_TRIGGER: 'AML_SCREENING_TRIGGER',
  BULK_AML_SCREENING_TRIGGER: 'BULK_AML_SCREENING_TRIGGER',
  CUSTOMER_AML_SCREENING_TRIGGER: 'CUSTOMER_AML_SCREENING_TRIGGER',

  BUSINESS_SEARCH_VIEW: 'BUSINESS_SEARCH_VIEW',
  BULK_BUSINESS_SEARCH_VIEW: 'BULK_BUSINESS_SEARCH_VIEW',

  BUSINESS_SEARCH_TRIGGER: 'BUSINESS_SEARCH_TRIGGER',
  BULK_BUSINESS_SEARCH_TRIGGER: 'BULK_BUSINESS_SEARCH_TRIGGER',
  CUSTOMER_BUSINESS_SEARCH_TRIGGER: 'CUSTOMER_BUSINESS_SEARCH_TRIGGER',

  CUSTOMERS_VIEW: 'CUSTOMERS_VIEW',
  CUSTOMERS_EDIT: 'CUSTOMERS_EDIT',

  PARTNER_MEMBERS_VIEW: 'PARTNER_MEMBERS_VIEW',
  PARTNER_MEMBERS_EDIT: 'PARTNER_MEMBERS_EDIT',

  PARTNERS_VIEW: 'PARTNERS_VIEW',
  PARTNERS_EDIT: 'PARTNERS_EDIT',

  PARTNER_SETTINGS_VIEW: 'PARTNER_SETTINGS_VIEW',
  PARTNER_BILLING_VIEW: 'PARTNER_BILLING_VIEW',
  PARTNER_TOPUP: 'PARTNER_TOPUP',
  API_KEY_EDIT: 'API_KEY_EDIT',
};

export const hasAnyRole = (user: any, roles: string[] = []): boolean => {
  const roleData = user.role || user.roles;
  if (hasNoValue(roleData)) return false;
  const rolesList = roles.map((it) => it.toLocaleLowerCase());
  if (typeof roleData === 'string') {
    const userRole = roleData.toLocaleLowerCase();
    return rolesList.indexOf(userRole) >= 0;
  }
  const rolesData = roleData?.map((it: any) => it.toLocaleLowerCase()) ?? [];
  return rolesData.some((r: any) => rolesList.indexOf(r) >= 0);
};

export const hasAllRoles = (user: any, roles: string[] = []): boolean => {

  const roleData = user.role || user.roles;
  if (hasNoValue(roleData)) return false;

  const rolesList = roles.map((it) => it.toLowerCase());

  if (typeof roleData === 'string') {
    const userRole = roleData.toLowerCase();
    return rolesList.includes(userRole);
  }

  const rolesData = roleData?.map((it: any) => it.toLowerCase()) ?? [];
  return rolesList.every((r: any) => rolesData.includes(r));
};

export const isRootAdminUser = (user: any): boolean => hasAnyRole(user, rootAdminUserRoles);

export const canEditStaff = (user: any): boolean => hasAnyRole(user, [appPermissions.PARTNER_MEMBERS_EDIT]);
export const canViewPartners = (user: any): boolean => hasAnyRole(user, [appPermissions.PARTNERS_VIEW]);

export const localRoutes = {
  callback: '/callback',
  pending: '/pending',
  partners: '/partners',
  priceBands: '/price-bands',

  dashboard: '/dashboard',
  customers: '/dashboard/customers',
  customerDetails: '/dashboard/customers/:customerId',

  policies: '/dashboard/policies',
  policyDetails: '/dashboard/policies/:policyId',

  wallets: '/admin/wallets',
  walletDetails: '/admin/wallets/:walletId',

  claims: '/dashboard/claims',
  claimDetails: '/dashboard/claims/:claimId',

  people: '/people',
  peopleUpload: '/people/upload',
  companies: '/companies',

  idVerifyPersonInfo: '/verify-national-id-data',

  bulkVerifyId: '/bulk-verify-national-id-data',
  bulkVerifyIdDetails: '/bulk-verify-national-id-data/:bulkId',
  bulkVerifyIdCreate: '/bulk-verify-national-id-data/create',

  verifyTin: '/verify-tin',

  consent: '/consent-requests',

  bulkConsent: '/bulk-consent-requests',
  bulkConsentDetails: '/bulk-consent-requests/:bulkId',
  bulkConsentCreate: '/bulk-consent-requests/create',

  verifyPhoneNumber: '/verify-phone-number',
  bulkVerifyPhoneNumber: '/bulk-verify-phone-number',
  bulkVerifyPhoneNumberDetails: '/bulk-verify-phone-number/:bulkId',
  bulkVerifyPhoneNumberCreate: '/bulk-verify-phone-number/create',

  amlScreening: '/aml-screening',
  bulkAmlScreening: '/bulk-aml-screening',

  businessSearch: '/business-search',
  bulkBusinessSearch: '/bulk-business-search',

  templates: '/templates',
  templateCreate: '/templates/create',

  dataCustodians: 'data-custodians',

  users: '/users',
  usersDetails: '/users/:userId',

  staff: '/staff',
  settings: '/settings',
};

const servers: any = {
  dev: {
    Pay: 'https://pay-staging.iotec.io',
    Lumen: 'https://lumen-staging.iotec.io',
    Messaging: 'https://messaging-staging.iotec.io',
    Auth: 'https://id.iotec.io',
    Crm: 'https://iotec-crm-staging.azurewebsites.net',
    Verify: 'https://localhost:7208',
    Verify_: 'https://verify-api-staging.iotec.io',
  },
  staging: {
    Pay: 'https://pay-staging.iotec.io',
    Lumen: 'https://lumen-staging.iotec.io',
    Messaging: 'https://messaging-staging.iotec.io',
    Auth: 'https://id.iotec.io',
    Verify: 'https://verify-api-staging.iotec.io',
    Crm: 'https://iotec-crm-staging.azurewebsites.net',
  },
  production: {
    Pay: 'https://pay.iotec.io',
    Lumen: 'https://lumen.iotec.io',
    Messaging: 'https://messaging.iotec.io',
    Auth: 'https://id.iotec.io',
    Crm: 'https://crm.iotec.io',
    Verify: 'https://verify-api.iotec.io',
  }
};

const envVar = process.env.REACT_APP_ENV || 'dev';

const environment = envVar.trim();
console.log(`############# Env : ${environment} ################`);

export const isDev = () => process.env.NODE_ENV === 'development';

const env = servers[environment];
const authUrl = env.Auth;
const crmUrl = env.Crm;
const verifyUrl = env.Verify;
const payUrl = env.Pay;
const lumenUrl = env.Lumen;
const messagingUrl = env.Messaging;

export const remoteRoutes = {
  pay: payUrl,
  lumen: lumenUrl,
  messaging: messagingUrl,
  auth: authUrl,
  crm: crmUrl,
  login: `${authUrl}/api/test/login`,
  profile: `${authUrl}/api/test/profile`,
  register: `${authUrl}/api/auth/register`,
  resetApiKey: `${verifyUrl}/api/auth/api-key/reset`,
  toggleApiKey: `${verifyUrl}/api/auth/api-key/toggle`,
  apiDocumentation: `${verifyUrl}/api-docs/index.html`,
  resetPass: `${authUrl}/reset`,

  contacts: `${crmUrl}/api/crm/contact`,
  contactSearch: `${crmUrl}/api/contact/search`,
  contactById: `${crmUrl}/api/contact/id`,
  contactsPerson: `${crmUrl}/api/crm/person`,
  contactsCompany: `${crmUrl}/api/crm/company`,
  contactsChc: `${crmUrl}/api/crm/person/chc`,
  contactsEmail: `${crmUrl}/api/crm/email`,
  contactsTag: `${crmUrl}/api/crm/tag`,
  contactsUrl: `${crmUrl}/api/crm/url`,
  contactsPhone: `${crmUrl}/api/crm/phone`,
  contactsAddress: `${crmUrl}/api/crm/address`,
  contactsIdentification: `${crmUrl}/api/crm/identification`,

  people: `${verifyUrl}/api/customers/person`,
  peopleUploadPreview: `${verifyUrl}/api/customers/import/preview`,
  peopleUploadSampleFile: `${verifyUrl}/api/customers/import/sample`,
  peopleUploadProcess: `${verifyUrl}/api/customers/import/process`,

  companies: `${verifyUrl}/api/customers/company`,
  customers: `${verifyUrl}/api/customers/customer`,
  customerIdentification: `${verifyUrl}/api/customers/identification`,
  customerPhone: `${verifyUrl}/api/customers/phone`,
  customerEmail: `${verifyUrl}/api/customers/email`,
  customerAddress: `${verifyUrl}/api/customers/address`,
  customerTagsCombo: `${verifyUrl}/api/customers/tag-combo`,

  kycProfile: `${verifyUrl}/auth/profile`,
  partners: `${verifyUrl}/api/partners`,
  partnersCallback: `${verifyUrl}/api/partner-callback`,
  partnersCombo: `${verifyUrl}/api/partners-combo`,
  partnerSettings: `${verifyUrl}/api/partner-settings`,
  partnersActivateDeactivate: `${verifyUrl}/api/partners/activate-deactivate`,

  partnerMembers: `${verifyUrl}/api/partner-members`,
  partnerMemberInvitation: `${verifyUrl}/api/partner-member-invitation`,
  partnerMembersActivateDeactivate: `${verifyUrl}/api/partner-members/activate-deactivate`,

  partnerProductCreate: `${verifyUrl}/api/products/create`,
  partnerProductTopUp: `${verifyUrl}/api/products/top-up`,
  partnerProductCategories: `${verifyUrl}/api/products/categories`,
  userRoles: `${verifyUrl}/api/auth/roles`,
  users: `${verifyUrl}/api/auth/users`,

  idVerification: `${verifyUrl}/api/idv/verify-person-information`,
  bulkIdVerificationPreview: `${verifyUrl}/api/idv/bulk-verify-person-information/preview`,
  bulkIdVerificationSample: `${verifyUrl}/api/idv/bulk-verify-person-information/sample`,
  bulkIdVerificationProcess: `${verifyUrl}/api/idv/bulk-verify-person-information/process`,
  bulkIdVerificationHistory: `${verifyUrl}/api/idv/bulk-verify-person-information/history`,

  phoneVerification: `${verifyUrl}/api/verify-phone-number`,
  bulkPhoneVerificationPreview: `${verifyUrl}/api/bulk-verify-phone-number-process/preview`,
  bulkPhoneVerificationSample: `${verifyUrl}/api/bulk-verify-phone-number-process/sample`,
  bulkPhoneVerificationProcess: `${verifyUrl}/api/bulk-verify-phone-number-process/process`,
  bulkPhoneNumberRequest: `${verifyUrl}/api/bulk-verify-phone-number`,

  tinVerification: `${verifyUrl}/api/ura/verify-tin-number`,

  amlScreening: `${verifyUrl}/api/aml-screening`,
  bulkAmlScreening: `${verifyUrl}/api/bulk-aml-screening`,

  businessSearch: `${verifyUrl}/api/business-search`,
  bulkBusinessSearch: `${verifyUrl}/api/bulk-business-search`,

  consentRequests: `${verifyUrl}/api/consent/consent-request`,
  consentRequestRawRequest: `${verifyUrl}/api/consent/consent-request/raw-request`,
  consentRequestByCustomer: `${verifyUrl}/api/consent/consent-request/by-customer`,
  consentRequestProcessing: `${verifyUrl}/api/consent/consent-request-process`,
  consentTemplatesCombo: `${verifyUrl}/api/consent/consent-template-combo`,

  bulkConsentPreview: `${verifyUrl}/api/consent/bulk-consent-request-process/preview`,
  bulkConsentSample: `${verifyUrl}/api/consent/bulk-consent-request-process/sample`,
  bulkConsentProcess: `${verifyUrl}/api/consent/bulk-consent-request-process/process`,
  bulkConsentRequest: `${verifyUrl}/api/consent/bulk-consent-request`,

  downloadConsentForm: `${verifyUrl}/api/consent/consent-pdf/download`,
  consentRequestLogs: `${verifyUrl}/api/consent/consent-request-process/audit`,

  consentTemplates: `${verifyUrl}/api/consent/consent-template`,
  dataCustodians: `${verifyUrl}/api/consent/data-custodian`,
  dataCustodianCombo: `${verifyUrl}/api/consent/consent-data-custodian-combo`,

  docs: `${verifyUrl}/api/docs`,
  docsDownload: `${verifyUrl}/api/docs/download`,
  manualTopUp: `${verifyUrl}/api/product-subscriptions/manual-top-up`,
  ioTecPayTopUp: `${verifyUrl}/api/products/iotec-pay-top-up`,

  purchaseOrderIoTecPayMomoPreview: `${verifyUrl}/api/product-purchase-orders-iotec-pay/mobile-money/preview-top-up`,
  purchaseOrderIoTecPayMomoInitiate: `${verifyUrl}/api/product-purchase-orders-iotec-pay/mobile-money/initiate-payment`,
  purchaseOrderIoTecPayMomoStatus: `${verifyUrl}/api/product-purchase-orders-iotec-pay/mobile-money/status`,

  purchaseOrderIoTecPayWalletSendOtp: `${verifyUrl}/api/product-purchase-orders-iotec-pay/iotec-wallet/send-otp`,
  purchaseOrderIoTecPayWalletVerifyOtp: `${verifyUrl}/api/product-purchase-orders-iotec-pay/iotec-wallet/verify-otp`,
  purchaseOrderIoTecPayWalletInitiate: `${verifyUrl}/api/product-purchase-orders-iotec-pay/iotec-wallet/initiate-payment`,
  purchaseOrderIoTecPayWalletStatus: `${verifyUrl}/api/product-purchase-orders-iotec-pay/iotec-wallet/status`,

  // route for product subscriptions
  productSubscriptions: `${verifyUrl}/api/product-subscriptions`,
  subscriptionCreditsUsage: `${verifyUrl}/api/product-subscriptions/credits-usage`,
  // route for price bands
  productPriceBands: `${verifyUrl}/api/product-price-bands`,
  productPriceBand: `${verifyUrl}/api/products/id`,
  // route for product purchase orders
  productPurchaseOrders: `${verifyUrl}/api/product-purchase-orders`,
  productPurchaseOrderInvoices: `${verifyUrl}/api/product-purchase-orders-invoices`,
  productPurchaseOrderInvoicesCombo: `${verifyUrl}/api/product-purchase-orders-invoices/combo`,

  productPurchaseOrderBankDeposit: `${verifyUrl}/api/product-purchase-orders-bank-deposit`,
  productPurchaseOrderBankDepositPay: `${verifyUrl}/api/product-purchase-orders-bank-deposit/pay`,
};
